import PropTypes from 'prop-types';

function Error({ statusCode, pageUrl }) {
    console.log(
        new Date().toISOString(),
        __filename,
        `An error ${statusCode} occurred on server. Page URL: ${pageUrl}`,
    );

    return (
        <p>
            {statusCode
                ? `An error ${statusCode} occurred on server. Page URL: ${pageUrl}`
                : 'An error occurred on client'}
        </p>
    );
}

Error.getInitialProps = ({ res, req, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    const pageUrl = req.url;
    return { statusCode, pageUrl };
};

export default Error;

Error.propTypes = {
    statusCode: PropTypes.number,
};
